<template>
<div>
    <v-navigation-drawer
      persistent
      :value="drawer"
      enable-resize-watcher
      fixed
      width="300px"
      class="grey lighten-4 text-uppercase font-weight-medium"
      app
      @input="$emit('trigger-drawer', $event)"
    >
      <div
        class="white pa-4 bb-1 text-center"
      >
        <v-img
           src="/img/cc-industrial-logo-trans-600x200.png"
          contain
        />
      </div>

      <v-list class="pt-0">
        <template v-for="item in items">
          <v-list-item
            v-if="!item.children"
            :key="item.text"
            :to="item.route"
            active-class="secondary white--text"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="item.children"
            :key="item.text"
            active-class="primary white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child) in item.children"
              :key="child.text"
              class="grey lighten-4"
              :to="child.route"
            >
              <v-list-item-title>{{ child.text}}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
</div>
</template>

<script>
import { getNavigationDrawerItems } from '@/core/constants'

export default {
  props: {
    drawer: {
      type: Boolean
    }
  },
  computed: {
    items () {
      return getNavigationDrawerItems()
    }
  }
}
</script>
